<template>
  <Layout>
    <template
      v-if="row.label"
      v-slot:label
    >
      {{ row.label }}
    </template>

    <template
      v-if="row.extra"
      v-slot:extra
    >
      {{ row.extra }}
    </template>

    <template v-slot:input>
      <!--
        <div class="btn btn-xs btn-fluid btn-dark text-center" @click.prevent="">
          Upload Image
        </div> -->

      <label
        class="block btn btn-xs btn-fluid btn-dark text-center"
        :for="inputId"
      >
        Replace Texture
      </label>

      <input
        :id="inputId"
        ref="fileSelector"
        class="hidden"
        name="asset"
        type="file"
        value="Upload Asset"
        accept=".png,.jpg,.jpeg,.gif"
        @change="handleChange"
      >

      <!-- <input type="file"
            class="btn btn-xs btn-fluid btn-dark-inverted"
            :class="row.class"
            v-on:change="handleChange"
            ref="fileSelector"
            accept=".png,.jpg,.jpeg,.gif"/> -->
    </template>
  </Layout>
</template>
<script>
import inputs from './InputMixin'

export default {
  name: 'FileInput',

  mixins: [inputs],

  data () {
    return {
      inputId: 'file-input'
    }
  },

  computed: {

    hasValue () {
      return true
    }
  },

  mounted () {
    this.generateId()
  },

  methods: {
    generateId () {
      this.inputId = 'file-input-' + Date.now()
    }
  }

}

</script>
